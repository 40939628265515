import ReCAPTCHA from "react-google-recaptcha";

const ReCaptcha = ({setEnabled}) => {
  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_CAPTCHA_KEY} // Provide valid KEY HERE
      onChange={() => setEnabled(true)}
    />
  );
};

export default ReCaptcha;
