import React from 'react';
import {useSearchParams} from "react-router-dom";
import languages from '../../supported_languages.json'

const LanguageDropdown = ({ defaultLang }) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const langSelect = (lang, name) => {
        searchParams.set('lang', lang)
        setSearchParams(searchParams);
        localStorage.setItem('currentLanguage', name)
        window.location.reload();
    }

    const resetLang = () => {
        localStorage.removeItem('currentLanguage')
        searchParams.delete('lang')
        setSearchParams(searchParams);
        window.location.reload();
    }

    const getDefaultLanguageName = () => {
        const found = languages.find(lang => {
            return lang.code === defaultLang
        });

        return found?.language
    }

    return (
        <div className="nav-wrapper">
            <div className="sl-nav">
                <ul>
                    <li>
                        {defaultLang && <>Change Language: <b>{localStorage.getItem('currentLanguage') ?? getDefaultLanguageName()}</b></>}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                        <div className="triangle"></div>
                        <ul>
                            {languages.map(lang => {

                                if (defaultLang === lang.code) {
                                    return null;
                                }
                                return (
                                    <li key={lang.code} onClick={() => {langSelect(lang.code, lang.language)}}>
                                        <span>{lang.language}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </li>
                    <li>
                        {localStorage.getItem('currentLanguage') && <div onClick={resetLang}>Reset to default</div>}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default LanguageDropdown;
