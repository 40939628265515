import {useEffect, useRef, useState} from "react";

import Stars from "../../components/starRating/Stars";

import RequestForm from "../requestForm/RequestForm";
import SurveyForm from "../surveyForm/SurveyForm";
import ConfirmationDialog from "../confirmation/ConfirmationDialog";
import {useSearchParams} from "react-router-dom";

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const StarRating = ({
                        defaultRating,
                        logo,
                        message,
                        stars,
                        name,
                        requestForm,
                        surveyForm,
                        platforms,
                        path,
                        cmdOverride,
                        setCmdOverride,
                        subcategories,
                        subcategoriesTranslated,
                        questionnaire,
                        isConfirmationActive,
                        translatedData
                    }) => {
        const [value, setValue] = useState(0); // Check if lower the threshold value. stars.threshold
        const prevValue = usePrevious(value);
        const [override, setOverride] = useState(null);
        const [confirmation, setConfirmation] = useState(false);
        const [rating, setRating] = useState(defaultRating);
        const [msg, setMsg] = useState("");
        const [categoriesRating, setCategoriesRating] = useState([]);
        const [isConfirmationOpen, setIsConfirmationOpen] = useState(true);
        // const [override, setOverride] = useState("request");

        const [searchParams] = useSearchParams();

        let lang = searchParams.get('lang')

        const modalOpen = ((parseInt(stars?.threshold) <= value && value !== 0 && !override) || override === "request")
            || ((parseInt(stars?.threshold) > value && value !== 0 && !override) || override === "survey")
            || ((confirmation && !override) || override === "category");

        useEffect(() => {
            if(((parseInt(stars?.threshold) <= value && value !== 0 && !override) || override === "request")) {
                // console.log("Submit request")
                // window.gtag("event", "action", "requestFormOpened");
                window.gtag("event", "formEvents", {request: "open"});
            } else if (((parseInt(stars?.threshold) > value && value !== 0 && !override) || override === "survey")) {
                // console.log("Submit survey")
                // window.gtag("event", "action", "surveyFormOpened");
                window.gtag("event", "formEvents", {survey: "open"});
            }

            // this sets dark bakground to all elements within main section except open modal
            if(modalOpen) {
                document.getElementById("overlay")?.remove();
                const main = document.querySelector("main .tw1-relative");
                const overlay = document.createElement("div");
                overlay.setAttribute("id", "overlay");
                main.appendChild(overlay);
                overlay.className = "tw1-absolute tw1-top-0 tw1-left-0 tw1-right-0 tw1-bottom-0 tw1-bg-black/[.7] tw1-h-screen tw1-z-20";

            } else {
                document.getElementById("overlay")?.remove();
            }
        }, [modalOpen, override, stars, value]);
        useEffect(() => {
            if (value === 0 && prevValue !== 0 && !cmdOverride) {
                setOverride(null)
            }

        }, [value, prevValue, cmdOverride])

        useEffect(() => {
            if (cmdOverride === "none") {
                setOverride(null)
                setValue(0)
                setCmdOverride(null)
            } else {
                setOverride(cmdOverride)
            }


        }, [cmdOverride, setCmdOverride])

        const categories = subcategories ? subcategories.toString().split(lang === 'ar' ? '،' : ',') : [];
        const categoriesTranslated = subcategoriesTranslated ? subcategoriesTranslated.toString().split(lang === 'ar' ? '،' : ',') : [];

        const params = new URLSearchParams(window.location.search);

        const surveyFormPostAction = async (rating) => {
            try {
                const rawResponse = await fetch(`${process.env.REACT_APP_API}${path}/surveyform`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        rating: rating,
                        author: `Anonymous`,
                        reviewtext: '',
                        urlparams: params.toString(),
                        categories: JSON.stringify(categoriesRating),
                    })
                });
                const content = await rawResponse.json();
                if (content.status === "ok") {
                    window.gtag("event", "formEvents", {survey: "submitted"});
                    setIsConfirmationOpen(false)
                    setConfirmation(false);
                }
            } catch (e) {
                console.log(5, e)
            }
        }

        const confirmationAction = () => {
            setConfirmation(true);
            setIsConfirmationOpen(true);
        }

        const submitConfirmation = () => {
            surveyFormPostAction(rating)
            setValue(rating)
        }

        // console.log(2, override, value)
        // console.log(3, cmdOverride)
        // console.log(4, ((parseInt(stars?.threshold) <= value && value !== 0 && !override) || override === "request"))
        console.log("Modal: ", modalOpen)
        return (
            <>
                {((confirmation && !override) || override === "category") ? (
                    <ConfirmationDialog
                        confirmation={confirmation}
                        setConfirmation={setConfirmation}
                        onClickSubmit={() => submitConfirmation()}
                        categories={categories}
                        categoriesTranslated={categoriesTranslated}
                        questionnaire={questionnaire}
                        categoriesRating={categoriesRating}
                        setCategoriesRating={setCategoriesRating}
                        stars={stars}
                        isConfirmationOpen={isConfirmationOpen}
                        setIsConfirmationOpen={setIsConfirmationOpen}
                        translatedData={translatedData}
                        setOverride={setOverride}
                        mainStarValue={rating}
                        ratingStars={<Stars
                            stars={stars}
                            rating={rating}
                            setRating={setRating}
                            msg={msg}
                            setMsg={setMsg}
                            setValue={setValue}
                            isConfirmationActive={isConfirmationActive}
                            clickAction={()=>{}}
                        />}
                    />
                ) : null}

                {((parseInt(stars?.threshold) <= value && value !== 0 && !override) || override === "request") ? (
                    <RequestForm
                        override={override}
                        setOverride={setOverride}
                        value={value}
                        setValue={setValue}
                        requestForm={requestForm}
                        platforms={platforms}
                    />
                ) : null}
                {((parseInt(stars?.threshold) > value && value !== 0 && !override) || override === "survey") ? (
                    <SurveyForm
                        override={override}
                        setOverride={setOverride}
                        path={path}
                        value={value}
                        setValue={setValue}
                        surveyForm={surveyForm}
                        translatedData={translatedData}
                        categories={categoriesRating} />
                ) : null}
                {<section
                    className='tw1-max-w-screen-sm sm:tw1-max-w-screen-md md:tw1-max-w-screen-lg lg:tw1-max-w-screen-xl tw1-px-3 tw1-mx-auto tw1-z-10'>
                    <div className='tw1-w-full tw1-bg-white'>
                        <div
                            className='tw1-py-[23px] tw1-px-[35px] tw1-mx-auto tw1-max-w-[560px] tw1-flex tw1-flex-col tw1-items-center'>
                            <div className='tw1-max-w-[360px] tw1-mx-auto'>
                                {logo && <img
                                    style={{maxWidth: '100%', display: 'block'}}
                                    src={logo}
                                    className='tw1-mb-8 tw1-mx-auto'
                                    alt={name}
                                />}
                                <p
                                    className='tw1-text-[12px] tw1-mb-1.5 tw1-text-[#6C757D] tw1-text-center'
                                    style={{lineHeight: 1.7}}
                                    dangerouslySetInnerHTML={{ __html: message }}
                                >
                                </p>
                            </div>
                            <Stars
                                stars={stars}
                                setValue={setValue}
                                rating={rating}
                                setRating={setRating}
                                msg={msg}
                                setMsg={setMsg}
                                isConfirmationActive={isConfirmationActive}
                                clickAction={isConfirmationActive ? confirmationAction : surveyFormPostAction}
                            />
                        </div>
                    </div>
                </section>}
            </>
        );
    }
;

export default StarRating;
