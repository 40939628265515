// convert/compress invalid svg https://jakearchibald.github.io/svgomg/
import { ReactComponent as Facebook } from "../../images/Facebook_Logo.svg";
import { ReactComponent as Google } from "../../images/Google_Logo.svg";
import { ReactComponent as HolidayCheck } from "../../images/HolidayCheck_Logo.svg";
import { ReactComponent as Tripadvisor } from "../../images/TripAdvisor_Logo.svg";
import { ReactComponent as Trustpilot } from "../../images/Trustpilot_Logo.svg";
import { ReactComponent as Website } from "../../images/Website_Logo.svg";

const Icon = ({ name }) => {
  switch (name) {
    case "facebook":
      return <Facebook />;

    case "google":
      return <Google />;

    case "holidaycheck":
      return <HolidayCheck />;

    case "tripadvisor":
      return <Tripadvisor />

    case "trustpilot":
      return <Trustpilot />

    case "custom":
      return <Website />

    default:
      return <div>Icon not found {name}</div>;
  }
};

const Platform = ({ name, url }) => {
  return (
      <div className='tw1-p-2'>
        <a
            className='tw1-bg-[#f5f5f8] tw1-py-7 tw1-px-[18px] tw1-flex tw1-items-center tw1-justify-center tw1-h-[96px]'
            href={url}
            target='_blank'
            rel='noreferrer noopener'
        >
          <Icon name={name} />
        </a>
      </div>
  );
};

const Platforms = ({ platforms }) => {
  return (
      <div className='tw1-flex tw1-items-center tw1-flex-wrap tw1-justify-center'>
        {platforms?.map(({ name, url }) => {
          return <Platform name={name} key={name} url={url} />;
        })}
      </div>
  );
};

export default Platforms;
