const Input = ({
                   onChange,
                   value,
                   placeholder,
                   label,
                   name,
                   type,
                   required,
               }) => {
    return (
        <div className='tw1-flex tw1-flex-col'>
            <label htmlFor={name} className='tw1-text-sm tw1-mb-1.5 tw1-font-medium'>
                {label}
            </label>
            <input
                type={type}
                className='tw1-w-full tw1-border tw1-rounded-md tw1-px-3 tw1-py-2'
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                required={required}
            />
        </div>
    );
};

export default Input;
