import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
// import reportWebVitals from './reportWebVitals';
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById(process.env.REACT_APP_CONTAINER_ID));
const companySlug = document.getElementById(process.env.REACT_APP_CONTAINER_ID).getAttribute("data-company-slug");
const fullApiUrl = document.getElementById(process.env.REACT_APP_CONTAINER_ID).getAttribute("data-full-api-url");
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App companySlug={companySlug} fullApiUrl={fullApiUrl} />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
