const Header = ({ name, header }) => {
    return (
        <section className='tw1-max-w-screen-sm sm:tw1-max-w-screen-md md:tw1-max-w-screen-lg lg:tw1-max-w-screen-xl tw1-px-3 tw1-mx-auto'>
            <div
                className='tw1-p-6 tw1-text-center tw1-w-full'
                style={{
                    color: header?.colortext ?? "black",
                    backgroundColor: header?.colorbackground ?? "yellow",
                }}
            >
                <p
                    style={{ textShadow: "0 2px 4px rgb(0 0 0 / 15%)", whiteSpace: "pre-wrap" }}
                    className='tw1-block tw1-mb-1.5 tw1-text-xs'
                    dangerouslySetInnerHTML={{ __html: header?.message }}>
                </p>
                {header?.showname ? (
                    <p
                        style={{ textShadow: "0 2px 4px rgb(0 0 0 / 15%)" }}
                        className='tw1-text-2xl tw1-font-bold tw1-block'
                    >
                        {name}
                    </p>
                ) : null}
            </div>
        </section>
    );
};

export default Header;
