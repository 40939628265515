// import dynamic from "next/dynamic";
import {useEffect, useState} from "react";
import {Dialog} from "@headlessui/react";

import Input from "../../components/surveyForm/Input";
import Close from "../../components/icons/Close";
import {useLocation} from 'react-router-dom';

import ReCaptcha from "./ReCaptcha";

import { AiOutlineSend } from "react-icons/ai";

const isStandalone = process.env.REACT_APP_IS_STANDALONE === "true";
const SurveyForm = ({value, setValue, surveyForm, path, setOverride, override, categories, translatedData}) => {
    const location = useLocation();
    // Show Captcha if surveyForm.captcha is true
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [enabled, setEnabled] = useState(true);
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(null)
    const params = new URLSearchParams(window.location.search);

    const submit = async () => {
        try {
            const rawResponse = await fetch(`${process.env.REACT_APP_API}${path}/surveyform`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    rating: value,
                    author: `${name} | ${email} | ${phone}`,
                    reviewtext: message,
                    urlparams: params.toString(),
                    categories: JSON.stringify(categories),
                })
            });
            const content = await rawResponse.json();
            if (content.status === "ok") {
                window.gtag("event", "formEvents", {survey: "submitted"});
                setSubmitted(true)
            }
        } catch (e) {
            setError(e.message)
            console.log(5, e)
        }
    }

    useEffect(() => {
        if (surveyForm?.captcha && !location.search.includes('disable-captcha')) {
            setEnabled(false)
        }
    }, [surveyForm, location.search])

    if (!isStandalone) {
        return (
                <div
                    onClose={() => setValue(0)}
                    className='tw1-absolute tw1-z-50 tw1-w-full tw1-top-[10px] tw1-left-1/2 tw1-transform tw1--translate-x-1/2 '
                >
                    <div
                        className='tw1-z-50

                         tw1-flex tw1-items-center tw1-justify-center tw1-p-4 ' >
                        <div
                            className='tw1-w-full tw1-rounded tw1-bg-white  tw1-max-w-[627px] tw1-rouded-[4px] tw1-relative tw1-max-h-full tw1-overflow-auto tw1-z-50'>
                            {submitted ? <div
                                className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6 tw1-flex tw1-justify-center tw1-items-center tw1-h-full'>
                                {surveyForm?.thanksmessage ? (
                                   <div
                                      style={{whiteSpace: "pre-wrap"}}
                                      dangerouslySetInnerHTML={{
                                          __html: surveyForm?.thanksmessage,
                                      }}
                                   ></div>
                                ) : null}
                            </div> : <div className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6'>
                                {surveyForm?.headermessage ? (
                                    <div
                                        className='tw1-text-xl tw1-font-medium tw1-mb-6 tw1-text-center tw1-text-[#374C51]'>
                                        <div
                                            style={{whiteSpace: "pre-wrap"}}
                                            dangerouslySetInnerHTML={{
                                                __html: surveyForm?.headermessage,
                                            }}
                                        ></div>
                                    </div>
                                ) : null}
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        submit()
                                    }}
                                    className='tw1-grid tw1-grid-cols-1 tw1-gap-y-4 tw1-text-black tw1-z-50'
                                >
                                    <Input
                                        type='text'
                                        value={name}
                                        onChange={setName}
                                        name='name'
                                        label='Name:*'
                                        required={true}
                                    />
                                    <Input
                                        type='text'
                                        value={phone}
                                        onChange={setPhone}
                                        name='phone'
                                        label='Phone:'
                                        required={false}
                                    />
                                    <Input
                                        type='email'
                                        value={email}
                                        onChange={setEmail}
                                        name='email'
                                        label='Email:*'
                                        required={true}
                                    />
                                    <div className='tw1-flex tw1-flex-col'>
                                        <label htmlFor='Message' className='tw1-text-sm tw1-mb-1.5 tw1-font-medium'>
                                            Message:*
                                        </label>
                                        <textarea
                                            required={true}
                                            rows={6}
                                            className='tw1-w-full tw1-border tw1-rounded-md tw1-p-3'
                                            value={message}
                                            name={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        ></textarea>
                                    </div>
                                    {surveyForm?.captcha ? <ReCaptcha setEnabled={setEnabled}/> : null}
                                    <button
                                        disabled={!enabled}
                                        className='send-button tw1-cursor-pointer tw1-w-max tw1-text-white tw1-border tw1-bg-[#39afd1] tw1-border-[#39afd1] tw1-font-normal tw1-rounded-sm disabled:tw1-cursor-not-allowed'
                                        type='submit'
                                    >
                                        <AiOutlineSend />
                                    </button>
                                </form>
                            </div>}
                            <div
                                className='tw1-absolute tw1-top-4 tw1-right-4 tw1-cursor-pointer'
                                onClick={() => setValue(0)}
                            >
                                <Close/>
                            </div>
                            {!submitted && <div
                                onClick={e => setOverride("request")}
                                className='tw1-border-t tw1-border-[#dae1e2] tw1-bg-[#b5bcc71a] tw1-p-7 tw1-text-xs tw1-font-medium tw1-text-center tw1-text-[#374C51]
           form-footer tw1-cursor-pointer tw1-underline'>
                                    {surveyForm?.footermessage ? (
                                    <div
                                        style={{whiteSpace: "pre-wrap"}}
                                        onClick={() => setValue(1)}
                                        dangerouslySetInnerHTML={{ __html: surveyForm?.footermessage, }}
                                    ></div>)
                                    : null}
                            </div>}
                        </div>
                    </div>
                </div>
        )
    } else {
        return (
            <Dialog
                open={(value && value > 0) || !!override}
                onClose={() => setValue(0)}
                className='tw1-relative tw1-z-50'
            >
                <div
                    className='tw1-fixed tw1-inset-0 tw1-flex tw1-items-center tw1-justify-center tw1-p-4 tw1-bg-gray-800 tw1-bg-opacity-50'>
                    <Dialog.Panel
                        className='tw1-w-full tw1-rounded tw1-bg-white  tw1-max-w-[627px] tw1-rouded-[4px] tw1-relative tw1-max-h-full tw1-overflow-auto'>
                        {submitted ? <div
                            className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6 tw1-flex tw1-justify-center tw1-items-center tw1-h-full'>
                            {surveyForm?.thanksmessage ? (
                               <div
                                  style={{whiteSpace: "pre-wrap"}}
                                  dangerouslySetInnerHTML={{
                                      __html: surveyForm?.thanksmessage,
                                  }}
                               ></div>
                            ) : null}
                        </div> : <div className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6'>
                            {surveyForm?.headermessage ? (
                                <Dialog.Title
                                    className='tw1-text-xl tw1-font-medium tw1-mb-6 tw1-text-center tw1-text-[#374C51]'>
                                    <div
                                        style={{whiteSpace: "pre-wrap"}}
                                        dangerouslySetInnerHTML={{
                                            __html: surveyForm?.headermessage,
                                        }}
                                    ></div>
                                </Dialog.Title>
                            ) : null}
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    submit()
                                }}
                                className='tw1-grid tw1-grid-cols-1 tw1-gap-y-4 tw1-text-black'
                            >
                                <Input
                                    type='text'
                                    value={name}
                                    onChange={setName}
                                    name='name'
                                    label={translatedData?.name + `*`}
                                    required={true}
                                />
                                <Input
                                    type='text'
                                    value={phone}
                                    onChange={setPhone}
                                    name='phone'
                                    label={translatedData?.phone}
                                    required={false}
                                />
                                <Input
                                    type='email'
                                    value={email}
                                    onChange={setEmail}
                                    name='email'
                                    label={translatedData?.email + `*`}
                                    required={true}
                                />
                                <div className='tw1-flex tw1-flex-col'>
                                    <label htmlFor='Message' className='tw1-text-sm tw1-mb-1.5 tw1-font-medium'>
                                        {translatedData?.message + `*`}
                                    </label>
                                    <textarea
                                        required={true}
                                        rows={6}
                                        className='tw1-w-full tw1-border tw1-rounded-md tw1-p-3'
                                        value={message}
                                        name={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    ></textarea>
                                </div>
                                {surveyForm?.captcha ? <ReCaptcha setEnabled={setEnabled}/> : null}
                                <button
                                    disabled={!enabled}
                                    className='send-button tw1-cursor-pointer tw1-w-max tw1-text-white tw1-border tw1-bg-[#39afd1] tw1-border-[#39afd1] tw1-font-normal tw1-rounded-sm disabled:tw1-cursor-not-allowed'
                                    type='submit'
                                >
                                    <AiOutlineSend />
                                </button>
                            </form>
                        </div>}
                        <div
                            className='tw1-absolute tw1-top-4 tw1-right-4 tw1-cursor-pointer'
                            onClick={() => setValue(0)}
                        >
                            <Close/>
                        </div>
                        {!submitted && <div
                            onClick={e => setOverride("request")}
                            className='tw1-border-t tw1-border-[#dae1e2] tw1-bg-[#b5bcc71a] tw1-p-7 tw1-text-xs tw1-font-medium tw1-text-center tw1-text-[#374C51]
           form-footer tw1-cursor-pointer tw1-underline'>
                            {surveyForm?.footermessage ? (
                              <div
                                style={{whiteSpace: "pre-wrap"}}
                                onClick={() => setValue(1)}
                                dangerouslySetInnerHTML={{ __html: surveyForm?.footermessage, }}
                              ></div>)
                            : null}
                              </div>}
                    </Dialog.Panel>
                </div>
            </Dialog>
        );
    }
};

export default SurveyForm;
