import React, {useState} from 'react';

const Feedback = ({ index, category, categoriesRating, setCategoriesRating, feedbackState, setFeedbackState, questionnaireTrigger, selectedStarValue, canShowQuestion, questionText, isRequired, translatedData, style={} }) => {

    const updateState = (name, text) => {
        let isExist = false;

        if(categoriesRating.length > 0) {
            const newState = categoriesRating.map(obj => {
                if (obj.name === name) {
                    if (obj.name === 'main') {
                        isExist = true;
                    }

                    return {...obj, question: questionText, answer: text};
                }
                return obj;
            });
            setCategoriesRating(newState);
        }

        if (!isExist && category === 'main') {
            setCategoriesRating([...categoriesRating, {name: name, question: questionText, answer: text, value: selectedStarValue}]);
        }

        setFeedbackState({...feedbackState, [index]: text})
    };

    return (
        <>
            {
                <div className="feedback-input-wrapper" style={style}>
                    { canShowQuestion && <h4>{questionText} {isRequired && <span style={{color: '#872424'}}>*</span>}</h4> }
                    <textarea required={isRequired} disabled={selectedStarValue === 0} className="feedback-input" onChange={(e) => {
                        updateState(category, e.target.value)
                    } }></textarea>
                    { !canShowQuestion && isRequired && <span style={{color: '#872424', fontStyle: 'italic', fontSize: '12px'}}>{translatedData?.mandatory}</span> }
                </div>
            }
        </>
    );
};

export default Feedback;
