import Socials from "./Socials";
import LanguageDropdown from "../translation/LanguageDropdown";
const Footer = ({ footer, socials, lang }) => {
    return (
        <section className='tw1-max-w-screen-sm  sm:tw1-max-w-screen-md md:tw1-max-w-screen-lg lg:tw1-max-w-screen-xl tw1-px-3 tw1-mx-auto'>
            {socials?.showfooter ? (
                <Socials socials={socials?.links.length ? socials?.links : []} />
            ) : null}
            <div className='tw1-bg-gray-150 tw1-w-full tw1-p-[26px] tw1-text-[10px] tw1-text-[#a3a3a3] tw1-flex tw1-items-center tw1-text-center tw1-justify-center' dangerouslySetInnerHTML={{ __html: footer?.showmessage ? footer?.message : null }}>
            </div>

            <LanguageDropdown defaultLang={lang} />

        </section>
    );
};

export default Footer;
